import NextImage from '@/components/NextImage';

export default function MedparBox4() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/emo.png'
        alt='EMO'
        width={255}
        height={240}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/info event nasionak 1.png'
        alt='Info event nasional'
        width={207}
        height={208}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/info-lomba-it.png'
        alt='Info Lomba IT'
        width={219}
        height={218}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Lomba SMA 1.png'
        alt='Lomba SMA'
        width={395}
        height={170}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/emo.png'
          alt='EMO'
          width={255}
          height={240}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/info event nasionak 1.png'
          alt='Info event nasional'
          width={207}
          height={208}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/info-lomba-it.png'
          alt='Info Lomba IT'
          width={219}
          height={218}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Lomba SMA 1.png'
          alt='Lomba SMA'
          width={395}
          height={170}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
