import NextImage from '@/components/NextImage';

export default function MedparBox7() {
  return (
    <div className='flex w-full items-center justify-center rounded-[24px] bg-typo-white p-8 max-lg:max-h-[250px] max-lg:gap-12 max-md:min-h-[136px] max-md:w-full max-[450px]:h-[180px] lg:h-[350px] lg:p-[40px]'>
      <NextImage
        src='/homepage/logo/Info event jatim 1.png'
        alt='Info Event Jatim'
        width={442}
        height={442}
        className='transition duration-500 hover:scale-[103%]'
        data-aos='zoom-in'
      />
    </div>
  );
}
