import(/* webpackMode: "eager" */ "/var/www/schematics24-frontend/node_modules/.pnpm/next@14.2.5_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/app/homepage/components/About.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/app/homepage/components/EventsDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/app/homepage/components/Home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/app/homepage/components/MediaPartner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/app/homepage/components/Sponsor.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/schematics24-frontend/src/components/NextImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/schematics24-frontend/src/layouts/Navbar.tsx");
