import NextImage from '@/components/NextImage';

export default function MedparBox1() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/ONIGIRI UKM 1.png'
        alt='Onigiri UKM'
        width={232}
        height={214}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Rokyuu mediapartner 1.png'
        alt='Rokyuu'
        width={214}
        height={214}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Pop Town Blue 1.png'
        alt='Pop Town'
        width={276}
        height={276}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Logo wibulover.id 1.png'
        alt='Wibulover.id'
        width={244}
        height={244}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/ONIGIRI UKM 1.png'
          alt='Onigiri UKM'
          width={232}
          height={214}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Rokyuu mediapartner 1.png'
          alt='Rokyuu'
          width={214}
          height={214}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Pop Town Blue 1.png'
          alt='Pop Town'
          width={276}
          height={276}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Logo wibulover.id 1.png'
          alt='Wibulover.id'
          width={244}
          height={244}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
