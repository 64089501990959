import NextImage from '@/components/NextImage';

export default function MedparBox5() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/Logo Cosclip.id Black 1.png'
        alt='Cosclip.id'
        width={336}
        height={68}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/kuliahrepeat.png'
        alt='Kuliah Repeat'
        width={191}
        height={191}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/info-event-1.png'
        alt='Info Event'
        width={296}
        height={234}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Surabaya punya info 1.png'
        alt='Surabaya Punya Info'
        width={349}
        height={270}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Logo Cosclip.id Black 1.png'
          alt='Cosclip.id'
          width={336}
          height={68}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/kuliahrepeat.png'
          alt='Kuliah Repeat'
          width={191}
          height={191}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/info-event-1.png'
          alt='Info Event'
          width={296}
          height={234}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Surabaya punya info 1.png'
          alt='Surabaya Punya Info'
          width={349}
          height={270}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
