import NextImage from '@/components/NextImage';

export default function SponsorBox2() {
  return (
    <div className='flex w-full items-center justify-center gap-[82px] rounded-[24px] bg-typo-white p-4 max-lg:h-full max-lg:gap-12 max-md:min-h-[136px] max-md:w-full max-sm:gap-4 lg:h-[350px] lg:p-[40px]'>
      <NextImage
        src='/homepage/logo/dewaweb-square-logo-rgb 1.png'
        alt='Dewaweb'
        width={280}
        height={332}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
      <NextImage
        src='/homepage/logo/logo pnp-1 1.png'
        alt='Print & Print'
        width={400}
        height={304}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
    </div>
  );
}
