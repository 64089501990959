'use client';

import AOS from 'aos';
import * as React from 'react';

import SponsorBox1 from '@/app/homepage/components/sponsor/SponsorBox1';
import SponsorBox2 from '@/app/homepage/components/sponsor/SponsorBox2';
import SponsorBox3 from '@/app/homepage/components/sponsor/SponsorBox3';
import SponsorBox4 from '@/app/homepage/components/sponsor/SponsorBox4';
import Typography from '@/components/Typography';

export default function OurSponsor() {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <section className='h-full min-h-screen bg-typo-main px-[120px] py-[60px] max-lg:px-[104px] max-lg:py-10 max-md:px-8'>
      {/* Title */}
      <div className='mx-auto flex w-full items-center justify-center gap-[60px] max-md:w-full max-md:gap-8 lg:mb-[60px]'>
        <div className='text-center'>
          <Typography
            variant='h3'
            weight='bold'
            font='Poppins'
            className='mb-[20px] text-center text-typo-white max-md:text-[32px]'
            data-aos='fade-up'
          >
            OUR SPONSOR
          </Typography>
        </div>
      </div>

      <div className='flex flex-col items-center gap-10 overflow-hidden max-md:gap-8 max-sm:gap-4'>
        <SponsorBox1 />
        <SponsorBox2 />
        <SponsorBox3 />
        <SponsorBox4 />
      </div>
    </section>
  );
}
