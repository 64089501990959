import NextImage from '@/components/NextImage';

export default function SponsorBox4() {
  return (
    <div className='flex w-full items-center justify-center gap-[82px] rounded-[24px] bg-typo-white p-6 max-lg:h-full max-lg:gap-12 max-md:min-h-[136px] max-md:w-full max-sm:gap-4 lg:h-[350px] lg:p-[40px]'>
      <NextImage
        src='/homepage/logo/SOSRO 1.png'
        alt='Tehbotol Sosro'
        width={270}
        height={172}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
      <NextImage
        src='/about/sponsor/logo-bonnet 2.png'
        alt='BONNET Hotel'
        width={190}
        height={172}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
      <NextImage
        src='/homepage/logo/SE_new_logo-01 1.png'
        alt='Solve Education'
        width={255}
        height={180}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
    </div>
  );
}
