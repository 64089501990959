import NextImage from '@/components/NextImage';

export default function MedparBox2() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/Logo UKM Japanese Culture garis tepi putih 1.png'
        alt='UKM Japanese Culture'
        width={216}
        height={216}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Logo pan.t.su 1.png'
        alt='pan.t.su'
        width={236}
        height={206}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/LogoMedparAve 1.png'
        alt='MedparAve Otaku Indonesia'
        width={280}
        height={280}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/podiumotaku.png'
        alt='Podium Otaku'
        width={348}
        height={146}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Logo UKM Japanese Culture garis tepi putih 1.png'
          alt='UKM Japanese Culture'
          width={216}
          height={216}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Logo pan.t.su 1.png'
          alt='pan.t.su'
          width={236}
          height={206}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/LogoMedparAve 1.png'
          alt='MedparAve Otaku Indonesia'
          width={280}
          height={280}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/podiumotaku.png'
          alt='Podium Otaku'
          width={348}
          height={146}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
