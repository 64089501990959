import NextImage from '@/components/NextImage';

export default function MedparBox5() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/Event Mahasiswa Surabaya 1.png'
        alt='Event Mahasiswa Surabaya'
        width={224}
        height={224}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/cs related 1.png'
        alt='CS Related'
        width={208}
        height={208}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Event jawa timur 1.png'
        alt='Event Jawa Timur'
        width={347}
        height={346}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Event Suroboyo 1.png'
        alt='Event Suroboyo'
        width={207}
        height={208}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Event Mahasiswa Surabaya 1.png'
          alt='Event Mahasiswa Surabaya'
          width={224}
          height={224}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/cs related 1.png'
          alt='CS Related'
          width={208}
          height={208}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Event jawa timur 1.png'
          alt='Event Jawa Timur'
          width={347}
          height={346}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Event Suroboyo 1.png'
          alt='Event Suroboyo'
          width={207}
          height={208}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
