'use client';

import AOS from 'aos';
import * as React from 'react';

import MedparBox1 from '@/app/homepage/components/medpar/MedparBox1';
import MedparBox2 from '@/app/homepage/components/medpar/MedparBox2';
import MedparBox3 from '@/app/homepage/components/medpar/MedparBox3';
import MedparBox4 from '@/app/homepage/components/medpar/MedparBox4';
import MedparBox5 from '@/app/homepage/components/medpar/MedparBox5';
import MedparBox6 from '@/app/homepage/components/medpar/MedparBox6';
import MedparBox7 from '@/app/homepage/components/medpar/MedparBox7';
import Typography from '@/components/Typography';

export default function OurMediaPartner() {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <section className='h-full min-h-screen bg-typo-main px-[120px] pb-[60px] max-lg:px-[104px] max-lg:py-10 max-md:px-8'>
      {/* Title */}
      <div className='mx-auto flex w-full items-center justify-center gap-[60px] max-md:w-full max-md:gap-8 lg:mb-[60px]'>
        <div className='text-center'>
          <Typography
            variant='h3'
            weight='bold'
            font='Poppins'
            className='mb-[20px] text-center text-typo-white max-md:text-[32px] lg:m-0'
            data-aos='fade-up'
          >
            OUR MEDIA PARTNER
          </Typography>
        </div>
      </div>

      <div className='flex flex-col items-center gap-10 overflow-hidden max-md:gap-8 max-sm:gap-4'>
        <MedparBox1 />
        <MedparBox2 />
        <MedparBox3 />
        <MedparBox4 />
        <MedparBox5 />
        <MedparBox6 />
        <MedparBox7 />
      </div>
    </section>
  );
}
