import NextImage from '@/components/NextImage';

export default function SponsorBox3() {
  return (
    <div className='flex w-full items-center justify-center gap-[82px] rounded-[24px] bg-typo-white p-6 max-lg:h-full max-lg:gap-12 max-md:min-h-[136px] max-md:w-full max-sm:gap-4 lg:h-[350px] lg:p-[40px]'>
      <NextImage
        src='/homepage/logo/CV. BUSANA JAYA ABADI 1.png'
        alt='CV. Busana Jaya Abadi'
        width={265}
        height={265}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
      <NextImage
        src='/homepage/logo/Dicoding Logo - Default Color 1.png'
        alt='Dicoding'
        width={615}
        height={174}
        data-aos='zoom-in'
        className='transition duration-500 hover:scale-[103%]'
      />
    </div>
  );
}
