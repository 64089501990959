import NextImage from '@/components/NextImage';

export default function SponsorBox1() {
  return (
    <div className='flex w-full items-center justify-center rounded-[24px] bg-typo-white p-8 max-lg:h-full max-lg:gap-12 max-md:min-h-[136px] max-md:w-full lg:h-[350px] lg:p-[40px]'>
      <NextImage
        src='/homepage/logo/Logo UBS Rose Gold 1.png'
        alt='UBS Gold'
        width={732}
        height={280}
        className='transition duration-500 hover:scale-[103%]'
        data-aos='zoom-in'
      />
    </div>
  );
}
