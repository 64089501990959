import NextImage from '@/components/NextImage';

export default function MedparBox3() {
  return (
    <div className='flex w-full items-center justify-center gap-[20px] rounded-[24px] bg-typo-white p-[20px] max-lg:h-full max-md:min-h-[136px] max-md:w-full max-md:gap-4 max-[450px]:flex-col lg:h-[350px]'>
      <NextImage
        src='/homepage/logo/Jfest Hunter 1.png'
        alt='Jfest Hunter'
        width={205}
        height={202}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Logo Japan Matsuri Surabaya 1.png'
        alt='Japan Matsuri Surabaya'
        width={202}
        height={202}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/media event 1.png'
        alt='Media Event'
        width={346}
        height={346}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <NextImage
        src='/homepage/logo/Logo Manga Siswa Sasjep 1.png'
        alt='Manga Siswa Sasjep'
        width={228}
        height={228}
        className='transition duration-500 hover:scale-[103%] max-[450px]:hidden'
        data-aos='zoom-in'
      />
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/Jfest Hunter 1.png'
          alt='Jfest Hunter'
          width={205}
          height={202}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Logo Japan Matsuri Surabaya 1.png'
          alt='Japan Matsuri Surabaya'
          width={202}
          height={202}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
      <div className='flex w-full items-center justify-center gap-[20px] min-[450px]:hidden'>
        <NextImage
          src='/homepage/logo/media event 1.png'
          alt='Media Event'
          width={346}
          height={346}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
        <NextImage
          src='/homepage/logo/Logo Manga Siswa Sasjep 1.png'
          alt='Manga Siswa Sasjep'
          width={228}
          height={228}
          className='transition duration-500 hover:scale-[103%]'
          data-aos='zoom-in'
        />
      </div>
    </div>
  );
}
